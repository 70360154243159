
// Page content
.page-content {
  width: 100%;
  max-width: 1600px;
  margin: auto;

  font-weight: 400;
  font-size: 18px;
  line-height: 160%;
  color: #fff;

  @include media-query($on-palm) {
      font-size: 16px;
  }
}

.background1, .background2 {
  margin-left: auto;
  margin-right: auto;
  padding-top: 2%;
  padding-bottom: 2%;
  padding-left: 15%;
  padding-right: 15%;

  @include media-query($on-palm) {
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 20px;
    padding-bottom: 40px;
  }
}

.photo-tile-grid {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(max(150px, 20%), 1fr) );
  column-gap: 10px;
  row-gap: 20px;
  justify-items: center;
}

.photo-text-grid {
  display: grid;
  grid-template-columns: 175px auto;
  column-gap: 20px;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;

  @include media-query($on-palm) {
    column-gap: 0px;
    row-gap: 20px;
    margin-bottom: 5%;
    margin-left: 0%;
    margin-right: 0%;
    grid-template-columns: unset;
  }
}

.photo-text-grid:not(:first-child) {
  padding-top: 15px;
}

.photo-tile {
  width: 150px;
  height: 150px;
  background-color: #fff;
  color: #000;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
}
.photo-tile>img {
  max-width: 125px;
  max-height: 100px;
  margin: auto;
}

.photo-text-grid .title {
  text-align: left;
}

.headline-large {
  font-weight: 500;
  font-size: 50px;
  line-height: 100%;
  text-align: center;
  margin-top: 3%;
  margin-bottom: 3%;

  @include media-query($on-palm) {
    font-size: 36px;
  }
}

.headline-medium {
  font-weight: 500;
  font-size: 42px;
  line-height: 100%;
  text-align: center;
  margin-top: 3%;
  margin-bottom: 3%;

  @include media-query($on-palm) {
    font-size: 28px;
  }
}

.headline-small {
  font-weight: 500;
  font-size: 24px;
  line-height: 100%;
  text-align: left;
  margin-top: 3%;
  margin-bottom: 3%;

  @include media-query($on-palm) {
    font-size: 21px;
  }
}

.title {
  font-weight: 700;
  font-size: 18px;
  line-height: 160%;
  text-align: center;
  margin-top: 1%;
  margin-bottom: 1%;

  @include media-query($on-palm) {
    font-size: 16px;
  }
}

// Site header
header {
  position: sticky;
  top: 0;
  width: 100%;
  max-width: 1600px;
  margin: auto;
}

.site-header {
  display: grid;
  grid-template-columns: 1fr repeat(1, auto) 1fr;
  grid-column-gap: 5px;
  justify-items: center;
}

.site-header>a:nth-child(1) { grid-column-start: 2; }
.site-header>a:nth-child(2) { margin-left: auto; }

.site-logo>img {
  margin-top: 5px;
  margin-bottom: 5px;
  height: 108px;

  @include media-query($on-palm) {
   height: 60px;
   margin-bottom: 5px;
  }
}

.ticket-link {
  padding-right: 8%;
  display: flex;
  align-items: center;
}

.ticket-link.desktop {
  @include media-query($on-palm) {
    display: none;
  }
}
.ticket-link.mobile {
  display: none;

  @include media-query($on-palm) {
    display: inline;
  }
}

.ticket-link.page {
  display: inline;

  @include media-query($on-palm) {
    display: block;
    margin-top: 5%;
    margin-bottom: 5%;
  }
}

.ticket-link:link, .ticket-link-page:link {
  text-decoration: none;
}

.ticket-link button {
  width: 225px;
  height: 50px;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  line-height: 110%;
  text-transform: uppercase;
  border: 0px;
  
  @include media-query($on-palm) {
    font-size: 21px;
  }
}

.ticket-link-page {
  display: flex;
  justify-content: center;
}

.ticket-link-page button {
  cursor: pointer;
  font-weight: 500;
  font-size: 42px;
  line-height: 110%;
  border-radius: 10px;
  padding: 10px;
  margin-top: 5%;
  margin-bottom: 5%;

  @include media-query($on-palm) {
    font-size: 28px;
  }
}

.page-buttons {
  display: flex;
  justify-content: center;
  gap: 10%;
  margin-top: 5%;
  margin-bottom: 5%;

  @include media-query($on-palm) {
    display: block;
  }
}

.page-buttons > a {
  padding-right: 0%;
}

.page-buttons > a > button{
  @include media-query($on-palm) {
    width: 100%;
  }
}

#menu-btn {
  position: absolute;
  top: -100%;
  left: -100%;
}

.menu {
  grid-column: 1 / -1;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}

.menu-icon, {
  visibility: hidden;
}

.menu-title {
  display: none; 
}

.menu a {
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 16px;
  line-height: 110%;
   color: #fff;
  display: inline-block;
  text-decoration: none;

  &:not(:last-child) {
    margin-right: 20px;
  }  
}

.menu a:hover {
  text-decoration: underline;
}

@include media-query($on-palm) {
  .menu-btn {
    display: none;
  }

  .menu-icon {
    cursor: pointer;
    justify-content: flex-end;
    align-items: baseline;
    padding: 30px 20px 30px 0;
    position: absolute;
    right: 4px;
    top: 4px;
    user-select: none;
    visibility: visible;
    z-index: 100;
  }

  .navicon {
    display: block;
    height: 2px;
    width: 25px;
    position: relative;
  }

  .navicon:before {
    top: 10px;
  }

  .navicon:after {
    top: -10px;
  }

  .navicon:before, .navicon:after {
    display: block;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    transition: all .2s ease-out;
  }

  .menu {
    grid-area: nav;
    max-width: unset;
    max-height: 0;
    height: 100vh;
    transition: max-height .2s ease-out;
    overflow: auto;    
    margin-right: 20px;
    margin-bottom: -40px;
    padding: 0;
    position: absolute;
    top: -70px;
    right: -20px;
    background-color: #000;
    background-image: unset;
    background: rgba(0, 0, 0, 0.5);
  }

  .menu div {
    width: 300px;
    display: flex;
    flex-direction: column;
    margin-left: auto; 
    margin-right: 0;
  }

  .menu-title {
    position: relative;
    text-align: left;
    margin-left: 25px;
    top: 9px;
    visibility: visible;
    display: block;
  }

  .ticket-link.mobile button {
    width: 250px;
  }

  .menu a:not(:last-child) {
    margin-right: 25px;
  }

  .menu a {
    text-align: left;
    text-decoration: none;
    margin-bottom: 0px;
    margin-left: 25px;
    margin-right: 25px;
    padding-bottom: 20px;
    padding-top: 15px;
    font-size: 18px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
  }

  .menu-btn:checked ~ .menu {
    max-height: 1500px;
  }

  .menu-btn:checked ~ .menu-icon .navicon {
    background: transparent;
  }

  .menu-btn:checked ~ .menu-icon .navicon:before {
    transform: rotate(-45deg);
    background: #fff;
  }

  .menu-btn:checked ~ .menu-icon .navicon:after {
    transform: rotate(45deg);
    background: #fff;
  }

  .menu-btn:checked ~ .menu-icon .navicon:before,
  .menu-btn:checked ~ .menu-icon .navicon:after {
    top: 0;
  }
}

// Slider
.slider {
  position: relative;
  top: -15px;
  margin-bottom: -15px;
  width: 100%;
  height: 700px;
  z-index: -100;

  @include media-query($on-palm) {
    height: 497px;
    padding-bottom: 5px;
  }
}

.slider ul {
  list-style-type: none;
}

.slider ul li { 
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  opacity: 0;

  @include media-query($on-palm) {
    height: 360px;
  }
}

.slider-text {
  position: relative;
  margin: auto;
  top: 55%;
  padding: 25px;
  max-width: 300px;

  @include media-query($on-palm) {
    padding-top: 20px;
    padding-bottom: 40px;
    padding-left: 20px;
    padding-right: 20px;
    top: 360px;
    width: unset;
    height: 80px;
    overflow: hidden;
    max-width: 400px;
  }
}

.slider-h1 {
  text-align: center;
}

.slider-h2 {
  text-align: center;
}

@keyframes imageAnimation { 
  0% { opacity: 0 }
  10% { opacity: 1 }
  40% { opacity: 1 }
  60% { opacity: 0}
}

footer {
  width: 100%;
  max-width: 1600px;
  margin: auto;
  flex: 1;

  font-weight: 400;
  font-size: 18px;

  @include media-query($on-palm) {
      font-size: 16px;
  }
}

hr {
  margin-top: 5%;
  margin-bottom: 5%;
  border-bottom: 1px solid;
}

// Mailing List
.mailing-list-socials {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @include media-query($on-palm) {
    grid-template-columns: 1fr;
  }
}

.mailing-list-socials .title {
  text-align: left;
}

.mailing-list-socials div div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mailing-list-socials div div svg {
  margin: 10px;
}


.mailing-list-socials div div a {
  display: block;
}

// Vendor Form
.iframe-form {
  display: block;
  width: 75%;
  height: 550px;
  margin: auto;
  border: none;

  @include media-query($on-palm) {
    width: 100%;
  }
}

// Site footer
.site-footer {
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 5%;
  padding-bottom: 5%;
  display: grid;
  grid-template-columns: 200px 4fr 4fr;
  column-gap: 15px;
  justify-items: left;
  font-size: 16px;

  @media (max-width:800px) {
    grid-template-columns: 1fr;
    padding-left: 5%;
    padding-right: 5%;
  }
}
.site-footer-logo {
  width: 200px;

  @media (max-width:800px) {
    margin-bottom: 5%;
  }
}

.site-footer ul {
  margin-left: 0px;
  list-style-type: none;
}

.site-footer ul li {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.site-footer ul li svg {
  margin: 10px;
}

.site-footer-copyright {
  padding-top: 2%;
  padding-bottom: 2%;
  text-align: center;
  color: #fff
}